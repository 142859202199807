<template>
  <div style="border-radius: 28px; background-color: #ffffff;">
    <b-row v-if="showFilterRow">
      <b-col
        md="12"
        class="px-5 py-2"
      >
        <h4><span style="text-decoration: underline;">Filter Columns:</span> </h4>
        <v-select
          v-model="FilterSelected"
          class="d-inline-block w-100"
          :options="SelectedItemFilterOptions"
          placeholder="Remove columns you don’t want displayed."
          multiple
          :searchable="true"
          :clearable="true"
        />
      </b-col>
    </b-row>

    <general-table
      ref="generalTable"
      :api-url="APIURL"
      :delete-content="true"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :columns="filteredColumns"
      :type="type"
      :per-page="perPage"
      :add-component-name="addComponentName"
      :selectcertificate="true"
      :certificate-options="certificate"
      :select-subcertificate="true"
      :search-and-select="true"
      :filter-columns="filterColumns"
      :has-filter-options="true"
    >
      <!-- :selected-item-search-options="SelectedItemSearchOptions" -->
      <!-- first reviewer control -->
      <template #cell(set_parent)="data">
        <SetParentCell :data="data" />
      </template>

      <!-- certificate control -->
      <template #cell(certificate)="data">
        <CertificateCell :data="data" />
      </template>

      <!-- sub certificate control -->

      <template #cell(sub_certificate)="data">
        <SubCertificateCell
          :data="data"
          :role="role"
        />
      </template>
      <!-- Email Sended control -->

      <template #cell(email_sended)="data">
        <EmailSendedCell :data="data" />
      </template>
      <!-- Require Audit control -->

      <template #cell(require_audit)="data">
        <RequireAuditCell :data="data" />
      </template>
      <!-- Invoice control -->

      <template #cell(invoice)="data">
        <InvoiceCell :data="data" />
      </template>
      <!-- Facility Name control -->

      <template #cell(facility_name)="data">
        <FacilityNameCell :data="data" />
      </template>
      <!-- category Request number control -->
      <template #cell(categoryRequestNo)="data">
        <CategoryRequestNoCell :data="data" />
      </template>

      <!-- last status update control -->
      <template #cell(last_status_update)="data">
        <LastStatusUpdateCell :data="data" />
      </template>

      <!-- payment status control -->

      <template #cell(payment_status)="data">
        <PaymentStatusCell
          :data="data"
          :role="role"
        />
      </template>

      <!-- Sticky Cell for Request Code  control-->
      <template #cell(request_code)="data">
        <RequestCodeCell :data="data" />
      </template>

      <!-- offer control -->
      <template #cell(offer)="data">
        <OfferCell :data="data" />

      </template>

      <!-- office control -->
      <template #cell(office)="data">
        <OfficeCell :data="data" />
      </template>

      <!-- first reviewer control -->
      <template #cell(first_reviewer)="data">
        <FirstReviewerCell
          :data="data"
        />
      </template>

      <!-- second reviewer control -->
      <template #cell(second_reviewer)="data">
        <SecondReviewerCell :data="data" />
      </template>

      <!-- application reviewer control -->
      <template #cell(application_reviewer)="data">
        <ApplicationReviewerCell :data="data" />
      </template>

      <!-- delegator control -->
      <template #cell(requester)="data">
        <DelegatorCell :data="data" />
      </template>

      <template #cell(alert)="data">
        <AlertCell
          :data="data"
          :get-all-data-pass="getAllDataPass"
        />
      </template>

    </general-table>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VSelect from 'vue-select'
import axios from 'axios'
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import decryptData from '@/store/auth/decryptData'
import DelegatorCell from './template-cell-requests-table/DelegatorCell.vue'
import FirstReviewerCell from './template-cell-requests-table/FirstReviewerCell.vue'
import SetParentCell from './template-cell-requests-table/SetParentCell.vue'
import CertificateCell from './template-cell-requests-table/CertificateCell.vue'
import SubCertificateCell from './template-cell-requests-table/SubCertificateCell.vue'
import RequireAuditCell from './template-cell-requests-table/RequireAuditCell.vue'
import InvoiceCell from './template-cell-requests-table/InvoiceCell.vue'
import FacilityNameCell from './template-cell-requests-table/FacilityNameCell.vue'
import CategoryRequestNoCell from './template-cell-requests-table/CategoryRequestNoCell.vue'
import LastStatusUpdateCell from './template-cell-requests-table/LastStatusUpdateCell.vue'
import PaymentStatusCell from './template-cell-requests-table/PaymentStatusCell.vue'
import OfferCell from './template-cell-requests-table/OfferCell.vue'
import OfficeCell from './template-cell-requests-table/OfficeCell.vue'
import SecondReviewerCell from './template-cell-requests-table/SecondReviewerCell.vue'
import ApplicationReviewerCell from './template-cell-requests-table/ApplicationReviewerCell.vue'
import AlertCell from './template-cell-requests-table/AlertCell.vue'
import EmailSendedCell from './template-cell-requests-table/EmailSendedCell.vue'
import RequestCodeCell from './template-cell-requests-table/RequestCodeCell.vue'

export default {
  components: {
    GeneralTable,
    VSelect,
    DelegatorCell,
    FirstReviewerCell,
    SetParentCell,
    CertificateCell,
    SubCertificateCell,
    RequireAuditCell,
    InvoiceCell,
    FacilityNameCell,
    CategoryRequestNoCell,
    LastStatusUpdateCell,
    PaymentStatusCell,
    OfferCell,
    OfficeCell,
    SecondReviewerCell,
    ApplicationReviewerCell,
    AlertCell,
    EmailSendedCell,
    RequestCodeCell,
  },
  data() {
    return {
      APIURL: 'unassigned-requests',
      viewContent: true,
      type: 'page',
      perPage: 50,
      filter: {},
      addComponentName: 'add-request',
      filterColumns: false,
      certificate: [],
      columns: [
        // { key: 'id', sortable: false },
        {
          key: 'request_code',
          stickyColumn: true,
          isRowHeader: true,
          label: 'Request Code',
        },
        { key: 'categoryRequestNo', label: 'category Req. No' },
        { key: 'set_parent', label: 'Set Parent' },
        { key: 'first_reviewer', label: 'first reviewer' },
        { key: 'first_reviewer_allocation_date', label: 'F.R Allocation Date' },
        { key: 'second_reviewer', label: 'second reviewer' },
        { key: 'second_reviewer_allocation_date', label: 'S.R Allocation Date' },
        { key: 'application_reviewer', label: 'application reviewer' },
        { key: 'application_reviewer_allocation_date', label: 'A.R Allocation Date' },
        { key: 'certificate', label: 'Certificate' },
        { key: 'sub_certificate', label: 'Sub Certificate' },
        { key: 'source_date', label: 'Allocation Date' },
        { key: 'last_status_update', label: 'Last Status Update' },
        { key: 'last_status', label: 'Last Status' },
        { key: 'application_status', label: 'Application Status' },
        { key: 'due_date', label: 'Due Date' },
        { key: 'source', label: 'Source' },
        { key: 'facility_name', label: 'Facility Name' },
        { key: 'delegator', label: 'Requester' },
        { key: 'payment_status', label: 'Payment Status' },
        { key: 'payment_method', label: 'P.Method' },
        { key: 'location', label: 'Country' },
        { key: 'office', label: 'Payment Country' },
        { key: 'offer', label: 'Offer' },
        { key: 'certificate_price', label: 'Final Price' },
        { key: 'payment_amount', label: 'Payment Amount' },
        { key: 'zoho_tax', label: 'Tax' },
        { key: 'alert', label: 'Highlight' },
        // { key: 'certificate_price', label: 'Certificate Price' },
        // { key: 'tax', label: 'Tax' },
        // { key: 'other_tax', label: 'Other Tax' },
        // { key: 'final_price', label: 'Final Price' },
        { key: 'wallet_balance', label: 'Wallet Balance' },
        // { key: 'edit_payment_status', label: 'Edit Payment Status' },
        { key: 'require_audit', label: 'Require Audit' },
        { key: 'email_sended', label: 'Email Sended' },
        // { key: 'set_offer', label: 'Set Offer' },
        { key: 'invoice', label: 'Invoice' },
      ],
      FilterSelected: [],
      SelectedItemFilterOptions: [
        { value: 'categoryRequestNo', label: 'Category Request Number' },
        { value: 'first_reviewer', label: 'First Reviewer' },
        { value: 'second_reviewer', label: 'Second Reviewer' },
        { value: 'application_reviewer', label: 'Application Reviewer' },
        { value: 'certificate', label: 'Certificate' },
        { value: 'sub_certificate', label: 'Sub Certificate' },
        { value: 'source_date', label: 'Allocation Date' },
        { value: 'last_status_update', label: 'Last Status Update' },
        { value: 'last_status', label: 'Last Status' },
        { value: 'application_status', label: 'Application Status' },
        { value: 'due_date', label: 'Due Date' },
        { value: 'source', label: 'Source' },
        { value: 'facility_name', label: 'Facility Name' },
        { value: 'delegator', label: 'Requester' },
        { value: 'payment_status', label: 'Payment Status' },
        { value: 'payment_method', label: 'Payment Method' },
        { value: 'location', label: 'Country' },
        { value: 'office', label: 'Payment Country' },
        { value: 'offer', label: 'Offer' },
        { value: 'certificate_price', label: 'Final Price' },
        // { value: 'certificate_price', label: 'Certificate Price' },
        // { value: 'tax', label: 'Tax' },
        // { value: 'other_tax', label: 'Other Tax' },
        // { value: 'final_price', label: 'Final Price' },
        { value: 'wallet_balance', label: 'Wallet Balance' },
        { value: 'require_audit', label: 'Require Audit' },
        { value: 'email_sended', label: 'Email Sended' },
        { value: 'invoice', label: 'Invoice' },
      ],

      role: localStorage.getItem('accessRole'),
    }
  },
  computed: {
    ...mapGetters('app', ['showFilterRow']),
    filteredColumns() {
      if (this.role === 'engineer-allocation') {
        const filterByRole = this.columns.filter(column => [
          // 'id',
          'categoryRequestNo',
          'set_parent',
          'first_reviewer',
          'first_reviewer_allocation_date',
          'second_reviewer',
          'second_reviewer_allocation_date',
          'application_reviewer',
          'application_reviewer_allocation_date',
          'source_date',
          'request_code',
          'payment_status',
          'source',
          'certificate',
          'sub_certificate',
          'created_at',
          'facility_name',
          'facility',
          'edit_payment_status',
          'last_status_update',
          'last_status',
          'requester',
        ].includes(column.key))
        if (!Array.isArray(this.FilterSelected) || this.FilterSelected.length === 0) {
          return filterByRole
        }
        const selectedRoleValues = this.FilterSelected.map(item => item.value)
        return filterByRole.filter(column => !selectedRoleValues.includes(column.key))
      }
      if (!Array.isArray(this.FilterSelected) || this.FilterSelected.length === 0) {
        return this.columns
      }
      const selectedValues = this.FilterSelected.map(item => item.value)
      return this.columns.filter(column => !selectedValues.includes(column.key))
    },
    apiUrl() {
      const base = 'unassigned-requests?'
      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      const queryParams = Object.entries(this.filter)
        .filter(([key, value]) => key && value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')

      return `${base}${queryParams}`
    },

  },
  watch: {
    FilterSelected(newVal) {
      if (newVal && newVal.length === 0) {
        localStorage.removeItem('FilterSelected')
      } else {
        localStorage.setItem('FilterSelected', JSON.stringify(this.FilterSelected))
      }
    },
  },
  beforeMount() {
    const getFilterSelected = localStorage.getItem('FilterSelected')
    this.FilterSelected = JSON.parse(getFilterSelected)
  },
  mounted() {
    axios
      .get('certificate_group?all=true')
      .then(res => {
        const dataCertificate = decryptData(res.data.payload)?.data
          ?.certificates
        this.certificate = dataCertificate.map(item => ({
          value: item.id,
          text: `${item.name?.ar} - ${item.name?.en}`,
        }))
      })
  },
  methods: {
    getAllDataPass(page) {
      this.$refs.generalTable.getAllData(page)
    },
  },
}
</script>

  <style lang="scss">
  .hover-pointer:hover {
    color: white; /* Change the property you want to affect on hover */
    background-color: #40aaf7;
    cursor: pointer;
    padding: 11.8px 0px;
  }
  .request-code-a {
    cursor: text;
  }
  .request-code-a:hover {
    color: white; /* Change the property you want to affect on hover */
  }
  .hover-pointer-empty:hover {
    color: white; /* Change the property you want to affect on hover */
    background-color: #40aaf7;
    cursor: pointer;
    padding: 20.2px 0px;
  }
  .downlaod-icon{
    color :#02111b
  }
  .downlaod-icon:hover{
    color : white
  }
  </style>
